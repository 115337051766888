<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <div class="banner_search_block">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-6 col-xxl-6">
                        <div class="search_block">
                            <h1>Hi, How can we help?</h1>
                            <form>
                                <div class="search"> <i class="fa fa-search"></i> 
                                    <input type="text" class="form-control" placeholder="Enter your search keywords..." name="search" #search="ngModel" [(ngModel)]="searchEntity.search" > 
                                    <button type="submit" class="theme_btn all_btn" [disabled]="((searchEntity.search && searchEntity.search.length>=3) ? false : true)" (click)="queAnsSearch()">Search</button> 
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-6 col-xxl-6">
                        <img src="../assets/images/faqs.png" alt="" class="img-fluid faq_img" />
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
<div class="faq_main_block">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <a (click)="redirectToQuestion('systemRequirement',1)" class="float-end system_link">System Requirements</a>
                <div class="clearfix"></div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3 d-flex">
                <div class="main_faq_block">
                    <div class="title">
                        <img src="../assets/images/da.png" alt="" class="img-fluid" />
                        <span>General</span>
                    </div>
                    <ul>
                        <li><a (click)="redirectToQuestion('general',1)">Getting Started</a></li>
                        <li><a (click)="redirectToQuestion('general',2)">All about License</a></li>
                        <li><a (click)="redirectToQuestion('general',3)">Personality Assessment</a></li>
                        <li><a (click)="redirectToQuestion('general',4)">Competency Assessment</a></li>
                        <li><a (click)="redirectToQuestion('general',5)">About Extension</a></li>
                    </ul>
                </div>
            </div>
           
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-3 d-flex">
                <div class="main_faq_block">
                    <div class="title">
                        <img src="../assets/images/ua.png" alt="" class="img-fluid" />
                        <span>For Administrators</span>
                    </div>
                    <ul>
                        <li><a (click)="redirectToQuestion('administrators',1)">Getting Started</a></li>
                        <li><a (click)="redirectToQuestion('administrators',2)">Your Organization</a></li>
                        <li><a (click)="redirectToQuestion('administrators',3)">User Management</a></li>
                        <li><a (click)="redirectToQuestion('administrators',4)">All about License</a></li>
                        <li><a (click)="redirectToQuestion('administrators',5)">About Extension</a></li>
                        <li><a (click)="redirectToQuestion('administrators',6)">Manage Assessment</a></li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="faq_block border_b">
                    <img src="../assets/images/gs.png" alt="" class="img-fluid" />
                    <h2>Getting Started</h2>
                    <p>About system requirements & how to get into AMSN.</p>
                    <a routerLink="/questions"><i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="faq_block border_b border_rl">
                    <img src="../assets/images/ua.png" alt="" class="img-fluid" />
                    <h2>User Administration</h2>
                    <p>Connecting Users with Organization to managing them, everything is here.</p>
                    <a routerLink="/questions"><i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="faq_block border_b">
                <img src="../assets/images/oa.png" alt="" class="img-fluid" />
                   <h2>Organization Administration</h2>
                    <p>About how to manage and administrate your organization.</p>
                    <a routerLink="/questions"><i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="faq_block">
                <img src="../assets/images/da.png" alt="" class="img-fluid" />
                    <h2>DCM Administration</h2>
                    <p>Manage the Licenses, Users, and Assessments for Digital Competency Model.</p>
                    <a routerLink="/questions"><i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="faq_block border_rl">
                <img src="../assets/images/li.png" alt="" class="img-fluid" />
                    <h2>Licenses</h2>
                    <p>Everything about the licenses.</p>
                    <a routerLink="/questions"><i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="faq_block">
                <img src="../assets/images/as.png" alt="" class="img-fluid" />
                    <h2>Assessments</h2>
                    <p>Learn about the Assessments.</p>
                    <a routerLink="/questions"><i class="fa fa-angle-double-right" aria-hidden="true"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>