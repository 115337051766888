import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  show_answers(index: number){
    $("#question_tab" + index + " .accordion-header button").removeClass("collapsed");
    $(".accordion-collapse").addClass("show");
    $("#showanswer_tab" + index).hide();
    $("#hideanswer_tab" + index).show();
  }

  hide_answers(index: number){
    $("#question_tab" + index + " .accordion-header button").addClass("collapsed");
    $(".accordion-collapse").removeClass("show");
    $("#showanswer_tab" + index).show();
    $("#hideanswer_tab" + index).hide();
  }

}
