import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }
  searchEntity;
  btn_disabled;
  ngOnInit(): void {
    this.searchEntity = {};
    this.btn_disabled = true;
  }

  queAnsSearch()
  {
    if(this.searchEntity.search.length >= 3)
    {
      this.btn_disabled = false
      localStorage.setItem('search',this.searchEntity.search);
      this.router.navigate(['/questions'])
    }
  }
  redirectToQuestion(tabname,tabvalue)
  {
    // alert(tabvalue);
    this.router.navigate(['/questions/'+ window.btoa(tabname) +'/'+window.btoa(tabvalue)])
  }

}
