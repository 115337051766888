<div class="container-fluid">
  <div class="row">
      <div class="col-12 col-sm-12 col-md-12">
          <div class="banner_search_block_inner">
              <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div class="search_block">
                          <h1>Hi, How can we help?</h1>
                          <div class="search"> <i class="fa fa-search"></i> <input type="text" class="form-control" value="framework" placeholder="Enter your search keywords..."> <button class="theme_btn all_btn" routerLink="/search">Search</button> </div>
                      </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <img src="../assets/images/faqs.png" alt="" class="img-fluid faq_img" />
                      <div class="clearfix"></div>
                  </div>
              </div>
              <div class="clearfix"></div>
          </div>
      </div>
  </div>
</div>
<div class="faq_tab_block search_block">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <h5>We found <span>5 questions</span> based on your search. <button class="clear_search_btn"><i class="fa fa-times" aria-hidden="true"></i>Clear search</button></h5>
        <ul class="nav nav-tabs main_tab_block float-start" id="mainTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="Assessments-tab" data-bs-toggle="tab" data-bs-target="#Assessments" type="button" role="tab" aria-controls="Assessments" aria-selected="false"><img src="../assets/images/da.png" alt="" class="img-fluid" /><span class="content"><h2>General (5)</h2></span></button>
          </li>
          <li class="nav-item system_link" role="presentation">
            <button class="nav-link" id="System-tab" data-bs-toggle="tab" data-bs-target="#System" type="button" role="tab" aria-controls="System" aria-selected="false">System Requirements</button>
          </li>
        </ul>
        <!-- <a class="float-end system_link mt-3">System Requirements</a> -->
        <div class="clearfix"></div>
        <div class="tab-content" id="mainTabContent">
          <div class="tab-pane fade" id="System" role="tabpanel" aria-labelledby="System-tab">...System</div>
          <div class="tab-pane fade show active" id="Assessments" role="tabpanel" aria-labelledby="Assessments-tab">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-2 pe-0 ps-0">
                  <div class="faq_tabs">
                      <ul class="nav nav-tabs" id="faqTab" role="tablist">
                          <li class="nav-item" role="presentation">
                              <button class="nav-link active" id="cat1-tab" data-bs-toggle="tab" data-bs-target="#cat1" type="button" role="tab" aria-controls="cat1" aria-selected="true">
                                  <span class="content"><h2>Getting Started (3)</h2></span>
                                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                              </button>
                          </li>
                          <li class="nav-item" role="presentation">
                              <button class="nav-link" id="cat2-tab" data-bs-toggle="tab" data-bs-target="#cat2" type="button" role="tab" aria-controls="cat2" aria-selected="false">
                                  <span class="content"><h2>All about License (1)</h2></span>
                                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                              </button>
                          </li>
                          <li class="nav-item" role="presentation">
                              <button class="nav-link" id="cat5-tab" data-bs-toggle="tab" data-bs-target="#cat5" type="button" role="tab" aria-controls="cat5" aria-selected="false">
                                  <span class="content"><h2>About Extension (1)</h2></span>
                                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                              </button>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-10 ps-0 pe-0">
                  <div class="faq_content">
                      <div class="tab-content" id="faqTabContent">
                          <div class="tab-pane fade show active" id="cat1" role="tabpanel" aria-labelledby="cat1-tab">
                              <div class="title_block float-start">
                                  <span class="content"><h2>Getting Started</h2></span>
                              </div>
                              <button class="all_btn theme_btn float-end" id="showanswer_tab1" (click)="show_answers(1)">Show all answers</button>
                              <button class="all_btn theme_btn float-end" id="hideanswer_tab1" (click)="hide_answers(1)" style="display: none;">Hide all answers</button>
                              <div class="clearfix"></div>
                              <div class="accordion" id="question_tab1">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="cat1ques1header">
                                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques1" aria-expanded="true" aria-controls="cat1ques1">
                                        What is Competency Framework?
                                      </button>
                                    </h2>
                                    <div id="cat1ques1" class="accordion-collapse collapse show" aria-labelledby="cat1ques1header">
                                      <div class="accordion-body">
                                        This is the placeholder for answer
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="cat1ques2header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques2" aria-expanded="false" aria-controls="cat1ques2"> I'm new to the Competency Framework. How do I create an Account?
                                      </button>
                                    </h2>
                                    <div id="cat1ques2" class="accordion-collapse collapse" aria-labelledby="cat1ques2header">
                                      <div class="accordion-body">
                                          This is the placeholder for answer
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="cat1ques3header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat1ques3" aria-expanded="false" aria-controls="cat1ques3">
                                        How do I Login into my Account for Competency Framework?
                                      </button>
                                    </h2>
                                    <div id="cat1ques3" class="accordion-collapse collapse" aria-labelledby="cat1ques3header">
                                      <div class="accordion-body">
                                          This is the placeholder for answer
                                      </div>
                                    </div>
                                  </div>
                                </div>
                          </div>
                          <div class="tab-pane fade" id="cat2" role="tabpanel" aria-labelledby="cat2-tab">
                              <div class="title_block float-start">
                                  <span class="content"><h2>User Administration</h2></span>
                              </div>
                              <button class="all_btn theme_btn float-end" id="showanswer_tab2" (click)="show_answers(2)">Show all answers</button>
                              <button class="all_btn theme_btn float-end" id="hideanswer_tab2" (click)="hide_answers(2)" style="display: none;">Hide all answers</button>
                              <div class="clearfix"></div>
                              <div class="accordion" id="question_tab2">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="cat2ques1header">
                                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques1" aria-expanded="true" aria-controls="cat2ques1">
                                            How do I invite or connect a user with an organization?	
                                      </button>
                                    </h2>
                                    <div id="cat2ques1" class="accordion-collapse collapse show" aria-labelledby="cat2ques1header">
                                      <div class="accordion-body">
                                        This is the placeholder for answer
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="cat2ques2header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques2" aria-expanded="false" aria-controls="cat2ques2">
                                            How do I invite/register multiple users?
                                      </button>
                                    </h2>
                                    <div id="cat2ques2" class="accordion-collapse collapse" aria-labelledby="cat2ques2header">
                                      <div class="accordion-body">
                                          This is the placeholder for answer
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id="cat2ques3header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques3" aria-expanded="false" aria-controls="cat2ques3">
                                          Does AMSN registration required for the invited User before using the platform?
                                      </button>
                                    </h2>
                                    <div id="cat2ques3" class="accordion-collapse collapse" aria-labelledby="cat2ques3header">
                                      <div class="accordion-body">
                                          This is the placeholder for answer
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques4header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques4" aria-expanded="false" aria-controls="cat2ques4">
                                            How can I see when User was connected?
                                        </button>
                                      </h2>
                                      <div id="cat2ques4" class="accordion-collapse collapse" aria-labelledby="cat2ques4header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques5header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques5" aria-expanded="false" aria-controls="cat2ques5">
                                            How do I disconnect a User from the Organization?
                                        </button>
                                      </h2>
                                      <div id="cat2ques5" class="accordion-collapse collapse" aria-labelledby="cat2ques5header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="cat2ques6header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat2ques6" aria-expanded="false" aria-controls="cat2ques6">
                                            What's the difference between Primary Contact and Organization Administrator?
                                        </button>
                                      </h2>
                                      <div id="cat2ques6" class="accordion-collapse collapse" aria-labelledby="cat2ques6header">
                                        <div class="accordion-body">
                                            This is the placeholder for answer
                                        </div>
                                      </div>
                                    </div>
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          <div class="tab-pane fade" id="Administrators" role="tabpanel" aria-labelledby="Administrators-tab">.Administrators..</div>
        </div>
      </div>
    </div>
  </div>
</div>