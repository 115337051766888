<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <div class="copyright_terms_block">
                    Copyright &copy; <span class="footer_year"></span>, <a href="https://www.amsn.org/" target="_blank">Academy of Medical-Surgical Nurses (AMSN)</a>. |
                    Powered by <a href="https://www.theopeneyes.com/" target="_blank">OpenEyes Technologies Inc.</a>
                </div>
                <div class="copyright_terms_block mt-2">No materials, including graphics, may be reused, modified, or
                    reproduced without written permission.</div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="social_links_block">
                    <a href="https://www.amsn.org/about-amsn/contact-us/?email_to=assessments#contactform" class="feedback_a" target="_blank">Share a Feedback</a>
                    <a href="https://www.facebook.com/MedSurgNurses" target="_blank"><i class="fa fa-facebook"></i></a>
                    <a href="https://twitter.com/medsurgnurses" target="_blank"><i class="fa fa-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/academy-of-medical-surgical-nurses" target="_blank"><i
                            class="fa fa-linkedin-square"></i></a>
                    <a href="https://www.instagram.com/amsnmedsurg/" target="_blank"><i class="fa fa-instagram"></i></a>
                    <a href="https://www.youtube.com/user/MedSurgNurses" target="_blank"><i
                            class="fa fa-youtube-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="clearfix"></div>